import * as React from "react"
import { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Main from "../components/Sumate/main"
import { getJobsAction } from "../redux/jobsDucks"
import { connect } from "react-redux"

const IndexPage = ({ dispatch }) => {

    useEffect(() => {
        dispatch(getJobsAction())
    },[])

    return (
      <Layout>
        <Seo title="Sumate" />
        <Main />
      </Layout>
    )
}

export default connect(state => ({
}),null)(IndexPage);