import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import CloseButton from "react-bootstrap/CloseButton"
import Form from "react-bootstrap/Form"
import ReCAPTCHA from "react-google-recaptcha"
import { connect } from "react-redux"
import { sendJobsForm } from "../../redux/jobsDucks"
import toast from "react-hot-toast";

const FormJobs = props => {
  const {
    jobData,
    stateProp,
    captcha,
    closeBtnAction,
    clientName,
    formText,
    clientId,
    dispatch,
  } = props
  // const [disabled, setDisabled] = useState(true)
  const [data, setData] = useState({
    position: jobData?.id,
    location: "",
    gender: "No deseo especificar",
    email: "",
    phone: "",
    extra_info: {},
    languages: {},
    interviews: {},
    portfolio: {},
    status: "En revisión"
  })

  const handleInputChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }
  const formatDateToISOString = dateString => {
    const date = new Date(dateString)
    date.setUTCHours(12)
    const formattedDate = date.toISOString().slice(0, -5) + "Z"
    return formattedDate
  }
  const handleDateChange = e => {
    e.persist()
    setData(prevData => ({
      ...prevData,
      [e.target.name]: formatDateToISOString(e.target.value),
    }))
  }

  const handleFileInput = e => {
    e.persist()
    const file = e.target.files[0]
    setData(prevData => ({
      ...prevData,
      cv: file,
    }))
  }

  // useEffect(() => {
  //   if (!captcha) {
  //     setDisabled(false)
  //   } else {
  //     setDisabled(true)
  //   }
  // }, [data])

  const handleSubmit = () => {
    const formData = {
      ...data,
      is_active: true,
      client: Number(clientId),
    }

    dispatch(sendJobsForm(formData))
    document.getElementById("jobsForm-"+jobData?.id).reset();
    return toast.success(<span>Tu solicitud ha sido enviada correctamente.<br/> Si tu perfil coincide con los requisitos, nos pondremos en contacto contigo.<br/> ¡Buena suerte!</span>);
  }

  return (
    <div
      className={
        "position-relative mediajobs-form" + (stateProp ? " active" : "")
      }
      id={`form-id-${jobData?.id}`}
    >
      <Container fluid>
        <Row>
          <Col xs={12} className="text-end my-3">
            <CloseButton
              className="my-2 my-0 mt-lg-5 mb-lg-3"
              onClick={() => closeBtnAction()}
            />
          </Col>
          <Col xs={12} lg={6} className="pe-lg-5 mb-4">
            <Form
              onSubmit={e => {
                e.preventDefault()
                handleSubmit()
              }}
              className="content-form jobsFormId"
              id={"jobsForm-"+jobData?.id}
            >
              <Row className="my-3">
                <h4>Datos personales</h4>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} xs={12} lg={6} controlId="formGridName">
                  <Form.Label className="mb-1">
                    Nombre y apellido<sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    name="first_name"
                    type="text"
                    placeholder="Ingresar..."
                    onChange={handleInputChange}
                    autoComplete="off"
                    autoFocus
                    required
                    style={{ borderRadius: "var(--customBorders)" }}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridGender">
                  <Form.Label className="mb-1">Género</Form.Label>
                  <Form.Select
                    name="gender"
                    defaultValue="No deseo especificar"
                    onChange={handleInputChange}
                  >
                    <option value="No deseo especificar">
                      No deseo especificar
                    </option>
                    <option value="Hombre">Hombre</option>
                    <option value="Mujer">Mujer</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  xs={12}
                  lg={6}
                  controlId="formGridDate"
                  className="mt-3 mt-lg-0"
                >
                  <Form.Label className="mb-1">Fecha de nacimiento</Form.Label>
                  <Form.Control
                    name="birth_date"
                    type="date"
                    onChange={handleDateChange}
                    autoComplete="off"
                    style={{ cursor: "pointer" }}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  lg={6}
                  controlId="formGridSurname"
                  className="mt-3 mt-lg-0"
                >
                  <Form.Label className="mb-1">Lugar de residencia</Form.Label>
                  <Form.Control
                    name="residence"
                    type="text"
                    placeholder="Ingresar..."
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Row>
              <Row className="my-3 mt-5">
                <h4>Datos de contacto</h4>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} xs={12} lg={6} controlId="formGridEmail">
                  <Form.Label className="mb-1">
                    Email<sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Ingresar..."
                    onChange={handleInputChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  xs={12}
                  lg={6}
                  controlId="formGridPhone"
                  className="mt-3 mt-lg-0"
                >
                  <Form.Label className="mb-1">Whatsapp</Form.Label>
                  <Form.Control
                    name="phone"
                    type="text"
                    placeholder="Ingresar..."
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3 mt-5">
                <h4>Curriculum vitae / CV</h4>
                <span>
                  (Opcional) Archivos compatibles: <sup>*</sup>pdf, <sup>*</sup>
                  doc, <sup>*</sup>jpg
                </span>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} xs={12} controlId="formGridWp">
                  <Form.Control
                    name="cv"
                    type="file"
                    onChange={e => handleFileInput(e)}
                    autoComplete="off"
                    style={{ cursor: "pointer" }}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3 mt-4">
                {/* <Form.Group as={Col} xs={12} controlId="formGridCaptcha">
                  {captcha ? (
                    <ReCAPTCHA
                      sitekey={captcha}
                      onChange={() => setDisabled(false)}
                    />
                  ) : null}
                </Form.Group> */}
                <Form.Group as={Col} xs={12} lg={6} controlId="formGridSubmit">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 mt-4"
                    // disabled={disabled}
                  >
                    Aplicar
                  </button>
                </Form.Group>
              </Row>
            </Form>
          </Col>
          <Col lg={6} className="d-none d-lg-block ps-lg-5 form-text">
            <h4 className="fw-bolder">{`¿Por qué unirse a ${clientName}?`}</h4>
            <br />
            <p className="fw-bolder text-title">Excelencia en el Servicio:</p>
            <p className="opacity-text mb-3 text-content">En Bellagamba, nos enorgullece ofrecer un servicio de primer nivel a nuestros clientes. Trabajamos incansablemente para superar sus expectativas y construir relaciones duraderas.</p>
            <p className="fw-bolder text-title">Crecimiento Profesional:</p>
            <p className="opacity-text mb-3 text-content">Brindamos oportunidades de desarrollo y crecimiento profesional, con un enfoque en la formación continua y la adquisición de habilidades en el mercado inmobiliario.</p>
            <p className="fw-bolder text-title">Cultura de Colaboración:</p>
            <p className="opacity-text mb-3 text-content">Valoramos la colaboración y el trabajo en equipo. Nuestro ambiente de trabajo fomenta la comunicación abierta y el intercambio de ideas.</p>
            <p className="fw-bolder text-title">Reputación Sólida:</p>
            <p className="opacity-text mb-3 text-content">Bellagamba es reconocida por su integridad, ética y resultados comprobados. Unirte a nosotros es ser parte de una empresa de confianza y prestigio.</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default connect()(FormJobs)
